<template>
<div class="background" id="background">
  <v-overlay :value="showProgress">
    <v-progress-circular :size="65" :width="5" color="#d82e3d" indeterminate/>
  </v-overlay>
  <webrtc
    ref="webrtc"
    :token="token"
    :socketURL="socketURL"
    :allowStream="allowStream"
    v-on:joined-room="logEvent"
    v-on:left-room="logEvent"
    v-on:opened-room="logEvent"
    v-on:share-started="logEvent"
    v-on:share-stopped="logEvent"
    @error="onError" />
</div>
</template>

<script>
import Vue from "vue";
import WebRTC from "../components/webrtc";
import Config from "../plugins/Config"

import * as io from "socket.io-client";
window.io = io;

Vue.component(WebRTC.name, WebRTC);

export default {
  name: 'Video',

  data() {
    return {
      token: this.$store.state.auth.token,
      allowStream: true,
      showProgress: true,
      socketURL: Config.URL.SOCKET_SERVER
    }
  },

  mounted: function() {
    this.showBottomRow(false);

    if(this.$store.state.auth.token !== this.$route.query.token) {
      this.$router.replace({
          name: 'User',
          query: {
              token: this.$route.query.token
          }
      }).catch(() => {});
    }else{
      this.checkMeetingStatus(this.token, 'N');
    }
  },

  beforeDestroy: function() {
    this.$refs.webrtc.leave();
  },

  methods: {
    showBottomRow(isShow) {
      if(isShow) document.getElementById("bottom-row").style.display = "block";
      else document.getElementById("bottom-row").style.display = "none";
    },
    onError(error, stream) {
      console.log("On Error Event", error, stream);
    },
    logEvent(event) {
      console.log("Event : ", event);
    },
    checkMeetingStatus(token, isHost) {
      this.$api._meeting
        .checkMeetingStatus({
            token: token,
            ishost: isHost
        })
        .then((response) => {
          if(response.data.status === "200"){
            this.$refs.webrtc.join(response.data.data.meeting_id);
            this.$store.dispatch("tokenInfo/setTokenInfo", {
              subject: response.data.data.subject,
              staffName: response.data.data.staff_name,
              meetingNo: response.data.data.meeting_no,
              availableStartTime: response.data.data.available_start_datetime,
            });
          }
          this.showProgress = false;
        });
    }
  }
}
</script>

<style scoped>
.background {
  margin: 0;
  padding: 0;
  height: calc(100vh - 85px);
}

@media screen and (max-width: 960px) {
    .background {
      margin: 0;
      padding: 0;
      height: calc(100%);
    }
}
</style>