<template>
<v-card flat class="background2" id="background2">
  <v-card id="video-layout" class="card-background rounded-lg">
    <v-row class="video-list" id="video-list">
      <template v-for="item in videoList" v-bind:video="item">
        <template v-if="item.streamType==='local'">
          <v-card v-bind:key="item.id" class="local-video rounded-lg" >
            <video autoplay playsinline ref="videos" class="video" width="100%" height="100%" :muted="item.muted" :id="item.id"/>
          </v-card>
        </template>

        <template v-else>
          <v-col v-bind:key="item.id" class="other-video d-flex flex-column" justify="center" align="center" :id="'col-'+item.id">
            <v-hover v-slot="{hover}">
              <v-card :class="{ 'on-hover': hover }" class="video-card rounded-lg">
                <v-row class="name-header" no-gutters>
                  <p class="name">{{item.name}}</p>
                </v-row>
                <video autoplay playsinline ref="videos" class="video" :muted="item.muted" :id="item.id"/>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </template>
    </v-row>
  </v-card>
  
  <v-card class="controller">
    <v-card class="timer rounded-xl" tile outlined align="center" justify="center" no-gutters >
      <p class="time">{{this.timer}}</p>
    </v-card>
    <v-row class="ma-0 pa-0" align="center" justify="center" no-gutters style="height:84px;">
      <v-btn icon style="margin: 12px;" color="#ffffff">
        <v-img v-if="!this.isMute" max-width="20px" max-height="29px" v-on:click="mute()" src="/../../img/svg/mic.svg"></v-img>
        <v-img v-if="this.isMute" max-width="26px" max-height="29px" v-on:click="unmute()" src="/../../img/svg/mute.svg"></v-img>
      </v-btn>
      <v-btn icon style="margin: 12px;" color="#ffffff">
        <v-img v-if="!this.isCloseCamera" max-width="29px" max-height="18px" v-on:click="closeCamera()" src="/../../img/svg/cam.svg"></v-img>
        <v-img v-if="this.isCloseCamera" max-width="29px" max-height="26px" v-on:click="openCamera()" src="/../../img/svg/nocam.svg"></v-img>
      </v-btn>
      <div class="divider" />
      <v-btn fab small class="start" color="#c81717" @click="hangup">
        <v-icon color="white">{{ "mdi-phone-hangup" }}</v-icon>
      </v-btn>
    </v-row>
  </v-card>
</v-card>
</template>

<script>
  import Vue from "vue"
  import VueSimpleAlert from "vue-simple-alert";
  import * as RTCMultiConnection from '../plugins/RTCMultiConnection.js';
  import * as RecordRTC from "recordrtc";
  import moment from 'moment';

  Vue.use(VueSimpleAlert);

  require('adapterjs');
  export default {
    name: 'webrtc',
    components: {
      RTCMultiConnection,
      RecordRTC
    },
    data() {
      return {
        name: this.$store.state.auth.name,
        fullVideo: false,
        rtcmConnection: null,
        localVideo: null,
        videoList: [],
        canvas: null,
        transparent: 'rgba(255, 255, 255, 0)',
        isMute: false,
        isCloseCamera: false,
        startTime: moment.now(),
        timer: '00:00:00'
      };
    },
    props: {
      // roomId: {
      //   type: String,
      //   default: 'public-room'
      // },
      token: {
        type: String,
        default: ''
      },
      socketURL: {
        type: String,
        default: ''
      },
      allowStream: {
        type: Boolean,
        default: true
      },
      cameraHeight: {
        type: [Number, String],
        default: 160
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      screenshotFormat: {
        type: String,
        default: 'image/jpeg'
      },
      enableAudio: {
        type: Boolean,
        default: true
      },
      enableVideo: {
        type: Boolean,
        default: true
      },
      enableLogs: {
        type: Boolean,
        default: false
      },
      stunServer: {
        type: String,
        default: null
      },
      turnServer: {
        type: String,
        default: null
      }
    },
    watch: {
    },
    mounted() {
      this.interval = setInterval(this.updateTimer, 1000);

      var that = this;
      this.rtcmConnection = new RTCMultiConnection(this);
      this.rtcmConnection.socketURL = this.socketURL;
      this.rtcmConnection.autoCreateMediaElement = false;
      this.rtcmConnection.enableLogs = this.enableLogs;
      this.rtcmConnection.session = {
        audio: this.enableAudio,
        video: this.enableVideo
      };
      this.rtcmConnection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: this.enableAudio,
        OfferToReceiveVideo: this.enableVideo
      };
      if ((this.stunServer !== null) || (this.turnServer !== null)) {
        this.rtcmConnection.iceServers = []; // clear all defaults
      }
      if (this.stunServer !== null) {
        this.rtcmConnection.iceServers.push({
          urls: this.stunServer
        });
      }
      if (this.turnServer !== null) {
        var parse = this.turnServer.split('%');
        var username = parse[0].split('@')[0];
        var password = parse[0].split('@')[1];
        var turn = parse[1];
        this.rtcmConnection.iceServers.push({
          urls: turn,
          credential: password,
          username: username
        });
      }
      this.rtcmConnection.onstream = function (stream) {
        let found = that.videoList.find(video => {
          return video.id === stream.streamid
        })
        if (found === undefined) {
          let video = {
            id: stream.streamid,
            muted: stream.type === 'local',
            isRecord: false,
            isMute: stream.type === 'local',
            streamType: stream.type,
            userid: stream.userid,
            name: stream.type === 'local' ? that.name : that.$store.state.user.users[stream.userid],
          };

          that.videoList.push(video);

          if (stream.type === 'local') {
            that.localVideo = video;
          }
        }

        setTimeout(function(){ 
          for (var i = 0, len = that.$refs.videos.length; i < len; i++) {
            if (that.$refs.videos[i].id === stream.streamid)
            {
              that.$refs.videos[i].srcObject = stream.stream;

              that.$refs.videos[i].onended = function() {
                that.rtcmConnection.onstreamended(stream);
              };
              break;
            }
          }
        }, 1000);

        that.$emit('joined-room', stream.streamid);
      };

      this.rtcmConnection.onstreamended = function (stream) {
        var newList = [];
        that.videoList.forEach(function (item) {
          if (item.id !== stream.streamid) {
            newList.push(item);
          }
        });
        that.videoList = newList;
        that.$emit('left-room', stream.streamid);
      };

      // document.getElementById("content").style.height = (window.innerHeight - 74)+'px';
      // document.getElementById("background2").style.height = (window.innerHeight - 74)+'px';
      // window.scrollTo(0, 0);

      window.addEventListener('resize', () => {
        // document.getElementById("content").style.height = (window.innerHeight - 74)+'px';
        // document.getElementById("background2").style.height = (window.innerHeight - 74)+'px';
        // window.scrollTo(0, 0);
      })
    },
    methods: {
      join(roomId) {
        var that = this;
        this.rtcmConnection.openOrJoin(roomId, function (isRoomExist, roomid) {
          if (isRoomExist === false && that.rtcmConnection.isInitiator === true) {
            that.$emit('opened-room', roomid);
          }
        });
      },
      leave() {
        this.rtcmConnection.attachStreams.forEach(function (localStream) {
          localStream.stop();
        });
        this.videoList = [];
        this.rtcmConnection.leave();
      },
      capture() {
        return this.getCanvas().toDataURL(this.screenshotFormat);
      },
      getCanvas() {
        let video = this.getCurrentVideo();
        if (video !== null && !this.ctx) {
          let canvas = document.createElement('canvas');
          canvas.height = video.clientHeight;
          canvas.width = video.clientWidth;
          this.canvas = canvas;
          this.ctx = canvas.getContext('2d');
        }
        const { ctx, canvas } = this;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas;
      },
      getCurrentVideo() {
        if (this.localVideo === null) {
          return null;
        }
        for (var i = 0, len = this.$refs.videos.length; i < len; i++) {
          if (this.$refs.videos[i].id === this.localVideo.id)
            return this.$refs.videos[i];
        }
        return null;
      },
      shareScreen() {
        var that = this;
        if (navigator.getDisplayMedia || navigator.mediaDevices.getDisplayMedia) {
          function addStreamStopListener(stream, callback) {
            var streamEndedEvent = 'ended';
            if ('oninactive' in stream) {
                streamEndedEvent = 'inactive';
            }
            stream.addEventListener(streamEndedEvent, function() {
                callback();
                callback = function() {};
            }, false);
          }

          function onGettingSteam(stream) {
            that.rtcmConnection.addStream(stream);
            that.$emit('share-started', stream.streamid);

            addStreamStopListener(stream, function() {
              that.rtcmConnection.removeStream(stream.streamid);
              that.$emit('share-stopped', stream.streamid);
            });
          }

          function getDisplayMediaError(error) {
            console.log('Media error: ' + JSON.stringify(error));
          }

          if (navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia({video: true, audio: false}).then(stream => {
              onGettingSteam(stream);
            }, getDisplayMediaError).catch(getDisplayMediaError);
          }
          else if (navigator.getDisplayMedia) {
            navigator.getDisplayMedia({video: true}).then(stream => {
              onGettingSteam(stream);
            }, getDisplayMediaError).catch(getDisplayMediaError);
          }
        }
      },
      mute() {
        var that = this;
        this.rtcmConnection.attachStreams.forEach(function (localStream) {
          if(localStream.type === 'local'){
            localStream.getAudioTracks()[0].enabled = false;
            that.isMute = true;
          }
        });
      },
      unmute() {
        var that = this;
        this.rtcmConnection.attachStreams.forEach(function (localStream) {
          if(localStream.type === 'local'){
            localStream.getAudioTracks()[0].enabled = true;
            that.isMute = false;
          }
        });
      },
      closeCamera() {
        var that = this;
        this.rtcmConnection.attachStreams.forEach(function (localStream) {
          if(localStream.type === 'local'){
            localStream.getVideoTracks()[0].enabled = false;
            that.isCloseCamera = true;
          }
        });
      },
      openCamera() {
        var that = this;
        this.rtcmConnection.attachStreams.forEach(function (localStream) {
          if(localStream.type === 'local'){
            localStream.getVideoTracks()[0].enabled = true;
            that.isCloseCamera = false;
          }
        });
      },
      hangup() {
        this.$confirm('Are you sure you want to leave?', '', 'question').then(() => {
          this.leave();
          this.$router.replace({
              name: 'Leave',
          });
        });
      },
      updateTimer() {
        var start = moment(this.startTime);
        var now = moment();

        this.timer = moment.utc(now.diff(start)).format("HH:mm:ss")
      },
    }
  };
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

  .background2 {
    background: transparent;
  height: calc(100vh - 95px);
  }

  .card-background {
    background: #2d2d2d;
    height: calc(100% - 48px);
    padding: 12px;
    margin: 24px;
  }

  .video-card {
    background: rgba(40, 40, 40, 1);
    height: 100%;
    padding: 0px;
    margin: 12px;
  }

  .video-list {
    background: #2d2d2d;
    height: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 0;
  }

  .local-video{
    position: absolute;
    background: rgba(105, 105, 105, 1);
    padding: 0px;
    margin: 0px;
    width: 22%;
    height: 22%;
    z-index: 1;
    right: 0px;
    bottom: 40px;
  }
  
  .other-video {
    background: #2d2d2d;
    padding: 0px;
    margin: 0px;
    z-index: 0;
    min-width: 50%;
  }

  .name-header {
    position: absolute;
    padding: 0;
    margin: 0;
    height: 12%;
    width: 100%;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0.1));
  }

  .name {
    position: absolute;
    padding: 0px;
    margin: 0px;
    padding-left: 20px;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    top: 50%;
    transform: translate(0%, -50%);
  }

  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }

  .show-btns {
    opacity: 1 !important;
  }

  .controller {
    position: absolute;
    background-image: linear-gradient(92deg, #23425a 0%, #243941 99%);
    /* opacity: 0.8; */
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 84px;
    z-index: 1;
    bottom: 0px;
  }

  .timer {
    position: absolute;
    background: white;
    padding: 0px;
    margin: 0px;
    width: 280px;
    height: 32px;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .time {
    position: absolute;
    padding: 0px;
    margin: 0px;
    width: 100%;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    top: 50%;
    transform: translate(0%, -50%);
  }
  
  .divider {
    width: 1px;
    height: 30px;
    background: #ffffff;
    margin: 0 28px 0 28px;
  }

  .start {
    margin: 12px;
    padding: 0px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none !important;
  }

  @media screen and (max-width: 960px) {
      .card-background {
          background: white;
          height: 100%;
          padding: 0px;
          margin: 0px;
      }

      .video-card {
        background: rgba(40, 40, 40, 1);
        height: 100%;
        padding: 0px;
        margin: 2px;
      }

      .video-list {
        background: white;
        height: calc(100% - 84px);
        padding: 0px;
        margin: 0px;
        z-index: 0;
      }

      .other-video {
        background: black;
        padding: 0px;
        margin: 0px;
        z-index: 0;
        min-width: 50%;
      }

      .timer {
        position: absolute;
        background: white;
        padding: 0px;
        margin: 0px;
        width: 180px;
        height: 32px;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
      .time {
        position: absolute;
        padding: 0px;
        margin: 0px;
        width: 100%;
        font-family: 'Open Sans';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        top: 50%;
        transform: translate(0%, -50%);
      }

      .local-video{
        position: absolute;
        background: rgba(105, 105, 105, 1);
        padding: 0px;
        margin: 0px;
        width: 22%;
        height: 22%;
        z-index: 1;
        right: 10px;
        bottom: 100px;
      }

      .controller {
        position: absolute;
        background-image: linear-gradient(92deg, #23425a 0%, #243941 99%);
        /* opacity: 0.8; */
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 84px;
        z-index: 1;
        bottom: 0px;
      }
  }
</style>
